<template>
    <div>
        <a-sidebar></a-sidebar>
        <div class="main-content" id="panel">
            <a-mobile-sidebar></a-mobile-sidebar>
            <a-header title="Employees" class="bg-orange text-white">
                <div class="col-md-12">
                    <stats-card card-title="Employees" card-sub-title="List of Employees">
                    </stats-card>
                </div>
            </a-header>
            <a-content>
                <div class="col-md-8">
                    <show-card v-if="showRecordPage" v-bind:cardTitle="showRecordData.fullname" v-bind:cardSubTitle="showRecordData.cadre.name">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <th><i class="fas fa-genderless"></i> &nbsp; &nbsp; Gender</th>
                                    <td>{{showRecordData.gender}}</td>
                                </tr>
                                <tr>
                                    <th><i class="fas fa-phone-square-alt"></i> &nbsp; &nbsp; Phone</th>
                                    <td>{{showRecordData.phone}}</td>
                                </tr>
                                <tr>
                                    <th><i class="fas fa-map-marker-alt"></i> &nbsp; &nbsp; Address</th>
                                    <td>{{showRecordData.address}}</td>
                                </tr>
                                <tr>
                                    <th><i class="fas fa-university"></i> &nbsp; &nbsp; Bank Name</th>
                                    <td>{{showRecordData.bank_name}}</td>
                                </tr>
                                <tr>
                                    <th><i class="fas fa-piggy-bank"></i> &nbsp; &nbsp; Bank Account</th>
                                    <td>{{showRecordData.bank_account_number}}</td>
                                </tr>
                                <tr>
                                    <th><i class="fas fa-money-bill"></i> &nbsp; &nbsp; Charge per day</th>
                                    <td>{{showRecordData.cadre.charge_per_day}} Naira</td>
                                </tr>
                                <tr>
                                    <th><i class="fas fa-store"></i> &nbsp; &nbsp; Branch</th>
                                    <td>{{showRecordData.cadre.branch.name}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-right">
                            <button @click="closeShowRecord" class="btn btn-default btn-sm my-4 "><i class="ni ni-fat-remove"></i> Close</button>
                        </div>
                    </show-card>
                    <table-card card-title="All Farm Employees" v-else>
                        <table class="table align-items-center table-flush" v-if="records">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col">Fullname</th>
                                <th scope="col">Cadre</th>
                                <th scope="col">Phone</th>
                                <th scope="col"><i class="ni ni-ui-04"></i></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="record in records" :key="record.employee_id">
                                <td>{{ record.fullname }}</td>
                                <td>{{ record.cadre.name }}</td>
                                <td>{{ record.phone }}</td>
                                <td>
                                    <button @click="openUpdateForm(record)" class="btn btn-secondary btn-sm"><i class="fas fa-edit"></i></button>
                                    <button @click="openShowRecord(record)" class="btn btn-dark btn-sm"><i class="fas fa-eye"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else class="alert alert-secondary">
                            No Records Yet!
                        </div>
                    </table-card>
                </div>
                <div class="col-md-4">
                    <form-card card-title="Edit Record Form" card-sub-title="Update Employee" v-if="showUpdateForm">
                        <form v-on:submit.prevent="updateForm">
                            <div class="form-group mb-3">
                                <div>
                                    <label>Employee Name</label>
                                    <input class="form-control" type="text" v-model="form.fullname">
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div>
                                    <label>Select Cadre</label>
                                    <select class="form-control" v-model="form.cadre_id">
                                        <option v-for="option in cadres" :value="option.cadre_id" :key="option.cadre_id">{{option.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Phone</label>
                                    <input class="form-control" type="text" v-model="form.phone">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Address</label>
                                    <input class="form-control" type="text" v-model="form.address">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Gender</label>
                                    <select class="form-control" v-model="form.gender">
                                        <option value="" disabled selected>Select Gender</option>
                                        <option v-for="option in genders" :value="option" :key="option">{{option}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Bank Name</label>
                                    <input class="form-control" type="text" v-model="form.bank_name">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Bank Account Number</label>
                                    <input class="form-control" type="text" v-model="form.bank_account_number">
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-warning my-4"><i class="ni ni-check-bold"></i> Update</button> &nbsp; 
                                <button @click="closeUpdateForm" class="btn btn-default my-4"><i class="ni ni-fat-remove"></i> Close</button>
                            </div>
                        </form>
                    </form-card>
                    <form-card card-title="New Form" card-sub-title="Create New Employee" v-else>
                        <form v-on:submit.prevent="createForm">
                            <div class="form-group mb-3">
                                <div>
                                    <label>Employee Name</label>
                                    <input class="form-control" type="text" v-model="form.fullname">
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div>
                                    <label>Select Cadre</label>
                                    <select class="form-control" v-model="form.cadre_id">
                                        <option v-for="option in cadres" :value="option.cadre_id" :key="option.cadre_id">{{option.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Phone</label>
                                    <input class="form-control" type="text" v-model="form.phone">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Address</label>
                                    <input class="form-control" type="text" v-model="form.address">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Gender</label>
                                    <select class="form-control" v-model="form.gender">
                                        <option value="" disabled selected hidden>Select Gender</option>
                                        <option v-for="option in genders" :value="option" :key="option">{{option}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Bank Name</label>
                                    <input class="form-control" type="text" v-model="form.bank_name">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label>Bank Account Number</label>
                                    <input class="form-control" type="text" v-model="form.bank_account_number">
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary my-4"><i class="ni ni-check-bold"></i> Create</button>
                            </div>
                        </form>
                    </form-card>
                </div>
            </a-content>
            <a-footer></a-footer>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import AdminContentVue from '../../components/Admin/AdminContent.vue'
import AdminFooterVue from '../../components/Admin/AdminFooter.vue'
import AdminHeaderVue from '../../components/Admin/AdminHeader.vue'
import NoButtonWhiteCardVue from '../../components/Cards/NoButtonWhiteCard.vue'
import StatsCardVue from '../../components/Cards/StatsCard.vue'
import TableOneButtonCardVue from '../../components/Cards/TableOneButtonCard.vue'
import AdminMobileNavVue from '../../components/Nav/AdminMobileNav.vue'
import AdminSideBarVue from '../../components/Nav/AdminSideBar.vue'
import TwoButtonColorCardVue from '../../components/Cards/TwoButtonColorCard.vue';
export default {
    name: 'AdminBranch',
    components: {
        'a-header': AdminHeaderVue,
        'a-mobile-sidebar': AdminMobileNavVue,
        'a-sidebar': AdminSideBarVue,
        'a-content': AdminContentVue,
        'a-footer': AdminFooterVue,
        'stats-card': StatsCardVue,
        'table-card': TableOneButtonCardVue,
        'form-card': NoButtonWhiteCardVue, 
        'show-card': TwoButtonColorCardVue
    },
    computed : {
        ...mapState({
            authToken : state => state.authToken,
            branch : state => state.branch,
        })
    },
    mounted(){
        if (this.authToken) {
            this.getRecords()
            this.fetchCadres()
        }
        else{
            this.$router.push('/login')
        }
    },
    data() {
        return{
            records: [],
            cadres: [],
            genders : ['male','female'],
            showUpdateForm: false,
            showRecordPage: false,
            form:{
                fullname: null,
                gender: '',
                phone: null,
                address: null,
                cadre_id: '',
                bank_name: null,
                bank_account_number: null
            }
        }
    },
    methods:{
        createForm(){
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/admin/employees?branch_id='+this.branch.branch_id, this.form,{ headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken 
                    }
                })
                 .then((response) => {
                    //Perform Success Action
                    if (response.status == '200') {
                        this.$moshaToast(response.data.message,{
                            type: 'success'
                        });
                        this.getRecords();
                        this.form = {}
                    }
                 })
                 .catch((error) => {
                    this.$moshaToast(error.response.data.message,{
                        type: 'danger'
                    });
                 })
                 .finally(() => {
                    //Perform other dependent actions
                    loader.hide()
                 });
        },

        updateForm(){
            let loader = this.$loading.show()
            axios.put(process.env.VUE_APP_BASEURL+'/admin/employees/'+this.recordId+'?branch_id='+this.branch.branch_id, this.form,{ 
                headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken 
                    }
                })
                 .then((response) => {
                    //Perform Success Action
                    if (response.status == '200') {
                        this.$moshaToast('Update Successful!',{
                            type: 'success'
                        });
                        this.getRecords();
                    }
                 })
                 .catch((error) => {
                    // error.response.status Check status code
                    if (error.response.status === 422) {
                        this.$moshaToast(JSON.stringify(error.response.data.errors),{
                            type: 'danger'
                
                        });                        
                    }else{
                        this.$moshaToast(error.response.data.message,{
                            type: 'danger'
                        });
                    }
                 })
                 .finally(() => {
                    //Perform other dependent actions
                    loader.hide()
                 });
        },

        getRecords(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/employees',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.records = response.data.data
            })
            .catch(error => {
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
        },

        fetchCadres(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/cadres',{
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken 
                    }
                })
                .then(response => {
                    this.cadres = response.data.data
                })
        },

        openUpdateForm(record){
            this.showUpdateForm = true,
            this.recordId = record.employee_id,
            this.form.fullname = record.fullname,
            this.form.gender = record.gender,
            this.form.cadre_id = record.cadre.cadre_id,
            this.form.phone = record.phone,
            this.form.address = record.address,
            this.form.bank_name = record.bank_name,
            this.form.bank_account_number = record.bank_account_number
        },
        closeUpdateForm(){
            this.showUpdateForm = false,
            this.form.fullname = null,
            this.form.gender = '',
            this.form.cadre_id = '',
            this.form.phone = null,
            this.form.address = null,
            this.form.bank_name = null,
            this.form.bank_account_number = null
        },
        openShowRecord(record){
            this.showRecordPage = true,
            this.showRecordData = record
        },
        closeShowRecord(){
            this.showRecordPage = false
        },

    },
}
</script>